.products-section {
    /* height: 300px; */
    background-color: #f5f5f5;
    /* margin: 20px;
    padding: 20px; */
    padding: 15px;
    /* max-width: 69rem; */
}

.card-style {
    /* width: 16rem; */
    padding: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.services-title {
    display: none;
}

@media (max-width: 769px) { 
    .card-style {
        width: 100%;
        margin-top: 15px;
        margin-bottom: 15px;
    }
}
