.content-two {
    background-color: #f5f5f5;
}

.content {
    padding-top: 10px;
    padding-bottom: 50px;

    /* height: 10em;
    display: flex;s
    align-items: center;
    justify-content: center */
}

.content-text {
    /* padding: 40px;
    margin-top: 60px; */

    /* height: 20em; */
    /* height: 10em; */
    /* display: flex; */
    /* align-items: center;
    justify-content: center */
}

/* .content-text p {
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%)
} */

/* Mobile  */
@media (max-width: 767px) { 
    .image-box {
        padding-left: 20px;
    }
}

/* Tablet */
@media (min-width: 768px) { 
    .content-text {
        /* padding: 40px; */
        margin-top: 20px;
    }
}



/* Desktop */
@media (min-width: 769px) { 
    .image-box {
        margin: 100px;
    }

    .content-text {
        padding: 40px;
        margin-top: 60px;
    }
}
