.review-tile {
    margin: 10px;
    height: 380px;
}

/* .reviews-grid {
    padding: 10px;
} */

.container {
    padding: 10px;
}

.reviews-section {
    /* background-color: #f5f5f5; */
    /* padding: 15px; */
}

.card-footer {
    background-color: #800000;
    color: #fff;
}