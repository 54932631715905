.main-container {
  padding: 0px!important;
}

.client-section {
  background-color: #f5f5f5;
}

.button-primary {
  background-color: #800000;
  border-color: #800000;
}

.button {
  background-color: #800000;
  color: white;
  padding: 0.5rem;
  width: 200px;
  /* text-align: center;
  align-items: center */
}

.services-grid {
  padding: 2rem;
}

.services-title {
  text-align: center;
  padding: 1rem;
}

.service-title {
  font-size: 18px;
}

.fa-font-icon {
  color: #800000;
  font-size: 24px;
  height: 40px;
  width: 40px;
}

.company-logo {
  font-size: 18px;
  color: #800000!important;
}

@media (max-width: 767px) { 
  .banner-content {
    margin-top: 150px;
  }
}

.footer {
  color: white;
  background-color:#292c2f;
  padding: 2rem;
}

.footer-list-links {
  text-align: left;
  margin-left: 0;
  color: white;
}

.terms-text {
  margin: 32px 0;;
}

.terms-heading {
  margin: 16px 0;
}
