.nav-section {
    background-color: #f8f9fa;
}

.navbar {
    max-width: 70rem;
    margin: 0 auto;
  }
  
  .nav-link-color {
    color: #800000!important;
  }
  
  .nav-link-color:hover {
    color: #212529!important;
  }

@media (max-width: 767px) { 
    .nav-logo {
        margin-left: 10px;
    }

    .menu-link-item {
        border-bottom: 1px solid rgba(33,34,36,.15);
    }

    .nav-link {
        /* font-size: 18px; */
    }
}

.company-logo {
  /* text-decoration: underline; */
}

